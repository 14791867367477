import React, { Component } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, connect, GLGlobal } from 'gl-commonui';
import { SecondaryTitle } from '@app/components';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { LocalProductDetail } from './components/local-detail';
import { StateType } from '@app/states';
import { getLocalProduct, updateLocalProduct, removeLocalProduct, goToLocalProductList, getRegions } from '@app/states/admin/product';
import { GlobalProductModel, LocalProductModel } from '@app/service/admin/products';
import { RegionModel } from '@app/service/admin/regions';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';

interface LocalProductEditPageProps {
    model?: LocalProductModel
    globalProducts?: GlobalProductModel[]
    candidateLocalProducts?: LocalProductModel[]
    regions?: RegionModel[]
    getLocalProduct: (query) => void
    updateLocalProduct: (product) => void
    removeLocalProduct: (id) => void
    getRegions: () => void
    goToLocalProductList: () => void
}

@withRouter
@connect(({ product: { globalList: globalProducts, localCandidateList: candidateLocalProducts, localModel: model, regions } }: StateType) => ({
    globalProducts,
    candidateLocalProducts,
    model,
    regions
}), {
        getLocalProduct,
        updateLocalProduct,
        removeLocalProduct,
        goToLocalProductList,
        getRegions
    })
export class LocalProductEditPage extends Component<RouteComponentProps<any> & LocalProductEditPageProps> {
    componentDidMount() {
        const {localProductId: productId} = this.props.match.params;
        this.props.getRegions();
        this.props.getLocalProduct(productId);
    }
    render() {
        const { globalProducts, candidateLocalProducts, updateLocalProduct, model, regions, removeLocalProduct, goToLocalProductList, match: { params: { localProductId: id }} } = this.props;
        return (
            <div className='content-layout'>
                <div className="product-detail product-detail-edit">
                    <SecondaryTitle title={GSAdminLocale.LocalProductEditTitle}>
                        <PageTitleDelBtn 
                            type="delete" 
                            className="delete" 
                            labelId={SchoolLocale.BtnDelete} 
                            deleteTitleId={GSAdminLocale.LocalProductModelDelete} 
                            delete={()=>removeLocalProduct(id)} 
                        />
                    </SecondaryTitle>
                    <LocalProductDetail
                        model = {model}
                        regions = {regions}
                        isEdit = {true}
                        globalProducts = {globalProducts}
                        candidateLocalProducts = {candidateLocalProducts}
                        handleSubmit={(product) => updateLocalProduct(product)} 
                        gotoList={goToLocalProductList}
                    />
                </div>
            </div>
        )
    }
}